import React from 'react'
import loadable from '@loadable/component'
import { Helmet } from 'react-helmet'
import { Container, Row, Col } from 'react-bootstrap'
import PageWrapper from '../components/PageWrapper'
import MetaData from '../components/MetaData'
import Hero from '../sections/common/Hero'
import { Section, Title, Text, Button } from '../components/Core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import LsCTA from '../components/LsCTA'
import setLanguage from '../helpers/languageConfig'
import cryptoPortfolio from '../assets/image/svg/cryptoPortfolio.svg'

const ExchTable = loadable(() => import('../components/Table/ExchTable'))

const Exchanges = props => {
  const { t, ready } = useTranslation(['exchanges', 'tables'], { useSuspense: false })
  const lang = setLanguage();
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
  
  const cta = {
    title: ready && t('missingTitle'),
    content: ready && t('missingText'),
    btnText: ready && t('missingBtn'),
    btnLink: `/${lang}/support`,
    icon: false,
  }

  return ready && (
    <>
      <MetaData page="exchanges" />
      <PageWrapper location={props.location.href} footerDark>
        <Hero
          bg={userDarkMode ? 'black' : 'bg'}
          title={t('heroTitle')}
          img={true}
          imgSrc={cryptoPortfolio}
          firstP={t('connectText')}
          secondP={t('riskFree')}
          externalLink={true}
          externalUrl={process.env.LS_SIGNUP}
          externalText={t('signUp')}>
          {t('exchangesIntro')}
        </Hero>
        <Section bg={userDarkMode ? 'black' : 'bg'} py={[40, null, null, 40]}>
          <Container>
            <Row>
              <Col lg="12">
                <ExchTable />
              </Col>
            </Row>
          </Container>
        </Section>
        <Section bg={userDarkMode ? 'black' : 'bg'} pt={[40, null, null, 40]}>
          <Container>
            <Row>
              <Col lg="12">
                <LsCTA userDarkMode={userDarkMode} cta={cta} />
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}

export default Exchanges
